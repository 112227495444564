import React from 'react';
import './prices.scss';
import { Link } from "gatsby"


const Prices = () => {


  return (

    <div className="pricingdiv">
      <ul className="theplan" style={{ textAlign: 'center' }}>
        <li className="title"><b>Kwartaal traject</b></li>
        <li><b>In 3 maanden bouwen aan een sterke fundering</b></li>
        <li><b>3 maanden</b></li>
        <li><b>5 drijfverenanalyses</b></li>
        <li><b>5 opstellingen</b></li>
        <li><b>5 stressanalyses</b></li>
        <li><Link className="pricebutton"
                  to="/traject"
                  target="_blank"
                  rel="nofollow"><span className="icon-tag"/>Neem contact op</Link></li>
      </ul>
      <ul className="theplan" style={{ textAlign: 'center' }}>
        <li className="title"><span className="icon-trophy" style={{ color: 'yellow' }}/> <b>Jaar abonnement</b></li>
        <li><b>In 12 maanden werken aan een sterk herstel</b></li>
        <li><b>12 maanden</b></li>
        <li><b>40 drijfverenanalyses</b></li>
        <li><b>40 opstellingen</b></li>
        <li><b>40 stressanalyses</b></li>
        <li><Link className="pricebutton"
                  to="/traject"
                  target="_blank"
                  rel="nofollow"><span className="icon-tag"/> Neem contact op</Link></li>
      </ul>
      <ul className="theplan" style={{ textAlign: 'center' }}>
        <li className="title"><b>½Jaar abonnement</b></li>
        <li><b>In 6 maanden werken aan een sterk herstel</b></li>
        <li><b>12 maanden</b></li>
        <li><b>20 drijfverenanalyses</b></li>
        <li><b>20 opstellingen</b></li>
        <li><b>20 stressanalyses</b></li>
        <li><Link className="pricebutton"
                  to="/traject"
                  target="_blank"
                  rel="nofollow"><span className="icon-tag"/> Neem contact op</Link></li>
      </ul>
    </div>

  )
}

export default Prices;


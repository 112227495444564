import React, { useRef, useEffect} from "react"
import './content-image.scss';
import PropTypes from "prop-types"
import { Link } from "gatsby"

const ContentImage = ({title, subTitle, img, pagina, children, reverse, className}) => {
  const refWrapper  = useRef(),
    refInnerWrapper = useRef()
  useEffect(() => {
    if(className.length > 0){
      refWrapper.current.classList.add(className)
      refInnerWrapper.current.classList.add('backdrop-blur')
    }
  })

  return (
    <div ref={refWrapper}>
      <div ref={refInnerWrapper} className={reverse ? 'flex-container ai-c jc-c row-reverse secs' : 'flex-container ai-c jc-c row secs'}>
        <div className={reverse ? "half block break-bg" : "half block"}>
          <div className="tiny-header">{title}</div>
          <div className="heading">
            <div>
              <h2 className={'subTitleContent'}>{subTitle}</h2>
            </div>
          </div>
          <div className="subhead">
            <div>{children}</div>
          </div>
          <div className="btn-container">
            <Link to={pagina} className="btn primary-lg round expand-on-hover">Lees meer</Link>
          </div>
        </div>
        <div className="half block overflow ">
          <div className="sbs-img-cont">
            <picture>{img}</picture>
          </div>
        </div>
      </div>
    </div>
  )
}

ContentImage.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  img: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  reverse: PropTypes.bool,
  className: PropTypes.string
}

ContentImage.defaultProps = {
  reverse: false,
  className: ''
}

export default ContentImage

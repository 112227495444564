import React from 'react';
import PropTypes from 'prop-types'
import './breaker.scss';

const Breaker = ({title, children}) => {
  return (
    <div className={'breaker'}>
      <div>
        <h3>{title}</h3>
        {children}
      </div>
    </div>
  )
}
Breaker.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired
}

Breaker.defaultProps = {
  title: 'Wij zijn Ziperr.'
}
export default Breaker;

import React, {useState} from 'react';
import './card.scss';
import { Link } from "gatsby"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from "@fortawesome/free-solid-svg-icons"
import PropTypes from "prop-types"


const Card = ( {icon, title, pagina, children}) => {

  let cardRef = React.createRef();

  let [color, setColor] = useState('#c5a86e');

  return (
    <div className="card" ref={cardRef} onMouseEnter={ () => setColor('#001830')} onMouseLeave={() => setColor('#c5a86e')}>
      <div className="face face1">
        <div className="content">
          {/*<img*/}
          {/*  src="https://github.com/Jhonierpc/WebDevelopment/blob/master/CSS%20Card%20Hover%20Effects/img/design_128.png?raw=true"/>*/}
          {/*  <i className="fab fa-accusoft"/>*/}
          <FontAwesomeIcon icon={icon} size={'4x'} color={color} />
            <h3>{title}</h3>
        </div>
      </div>
      <div className="face face2">
        <div className="content">
          {children}
          <Link to={`/${pagina}`}>Lees meer</Link>
        </div>
      </div>
    </div>
  );
}

Card.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.any
}

Card.defaultProps = {
  title: `Motivatie`,
  children: `At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium`,
  icon: faCoffee
}

export default Card;

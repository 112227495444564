import React from 'react';
import './footer.scss'
const Footer = () => {
  return (
    <footer id="footer">
      <div className="inner">
        <h3>Kom in contact met ons via de mail!</h3>
        <form action="#" method="post">
          <div className="field half first">
            <label htmlFor="name">Naam</label>
            <input name="name" id="name" type="text" placeholder="Naam"/>
          </div>
          <div className="field half">
            <label htmlFor="email">Email</label>
            <input name="email" id="email" type="email" placeholder="Email"/>
          </div>
          <div className="field">
            <label htmlFor="message">Bericht</label>
            <textarea name="message" id="message" rows="6" placeholder="Bericht"></textarea>
          </div>
          <ul className="actions">
            <li><input value="Verstuur bericht" className="button alt" type="submit"/></li>
          </ul>
        </form>
        <div className="copyright">
          © Zipperr. Design: <a href="https://Oussama.co">Oussama</a>. Images: <a
          href="https://unsplash.com">Unsplash</a>.
        </div>
      </div>
    </footer>
  )
}

export default Footer;
